<template>
<div>
  <hr class="mobile-seperator" />
  <div class="content">
    <div class="row info px-md-4" :class="{ 'no-margin': parentComp == 'exhibit' }">
      <div class="col-md-4 image-container h-100 px-0">
        <img :src="files.path" :alt="files.filename" class="brand-image" />
      </div>

      <div class="note-details-container col-md-5 d-flex flex-column px-4">
        <h1 v-html="exhibit.title" class="note-heading pd-0 mb-0"></h1>
        <div class=" white-text mt-4">
          <p v-html="exhibit.short_text" class="note-details"></p>
        </div>
      </div>

      <div class="col-md-3 mx-0 px-0 h-100">
        <div class="d-flex side-buttons-container w-100 justify-content-md-end align-items-center">
          <div class="d-flex flex-column columns-button">
            <button type="button" class="d-flex justify-content-start btn right-buttons mx-0 mb-2 " @click="ShowProduct()">{{ $t('views.buttons.product') }}</button>
            <button type="button" class="d-flex justify-content-start btn right-buttons mx-0 offer-btn" @click="goToChat()">Live Chat anfragen</button>
          </div>
        </div>
      </div>
      <hr class="mobile-seperator" />
      </div>
      <div class='row p-0 m-0 lower'>
        <div class="col-md-12 cover-content d-flex align-items-center justify-content-center p-0 m-0">
          <textarea class="note text-center" :placeholder="$t('views.placeholders.note_body')" v-model="note.text"></textarea>
        </div>
        <div class="d-flex flex-column flex-md-row align-items-center note-bottons-container p-0 m-0">
          <button type="button" class="btn px-4 note-button d-flex justify-content-start ml-md-0 mt-md-0 mr-md-4" @click="submitNote">
            <i class="fas fa-save px-2" aria-hidden="true"></i>
            <span class="button-text">{{ $t('views.buttons.save') }}</span>
          </button>
          <button type="button" @click="shareProduct" class="btn px-4 note-button d-flex justify-content-start ml-md-0 mt-md-0 mr-md-4">
            <i class="fas fa-share-alt px-2" aria-hidden="true"></i>
            <span class="button-text">{{ $t('views.buttons.share') }}</span>
          </button>
          <button type="button" class="btn px-4 note-button d-flex justify-content-start mr-md-0 ml-md-0 mt-md-0" @click="deleteNoteBody">
            <i class="fas fa-times-circle px-2" aria-hidden="true"></i>
            <span class="button-text">{{ $t('views.buttons.delete') }}</span>
          </button>
        </div>
      </div>
  </div>

</div>
</template>

<script>
export default {
  name: 'NotesDetails',
  props: {
    toggleProduct: Function,
    getNotes: Function,
    toggleShowProduct: Function,
    toggleShowChat: Function,
    setSelectedUser: Function,
    selectedExhibit: Object,
    showNoteMessage: Function,
    setSelectedExhibit: Function
  },
  data(){
    return {
      note: {
        exhibit_uuid: this.selectedExhibit.uuid,
        text: ''
      },
      exhibit: {},
      files: [],
      parentComp: this.$route.name
    };
  },
  methods: {
    submitNote() {
      this.$http.post('/note', this.note).then( () => {
        this.showNoteMessage(true);
      })
        .catch( () => {
          this.showNoteMessage(false);
        });

    },
    deleteNoteBody() {
      this.note.text = '';
      this.$emit('delete-note', this.exhibit);
    },
    shareProduct() {
      var embeddedLink = process.env.VUE_APP_URL + '/product/' + this.exhibit.uuid;
      var link = 'mailto:?'
                + 'body=' + this.$t('views.mail_body', { link: embeddedLink });

      window.open(link);
    },
    async goToChat() {
      if (this.$store.getters.isAuthorized) {
        if (
          localStorage.getItem('user-roles').includes('exhibitor') ||
          localStorage.getItem('user-roles').includes('organizer')
        ) {
          this.$router.push('/exhibitor/' + this.exhibit.contact);
        } else {
          await this.$http.post(
            'distributor-call',
            JSON.stringify({ customer: this.$store.state.user.uuid })
          ); 
          this.$store.state.modalMessage = 'Chat wurde angefragt. Ein Mitarbeiter meldet sich in Kürze.';
          this.$store.state.modalIcon = 'fas fa-check-circle';
          this.$store.state.modalClass = 'green-text';
          this.$store.state.modal = true;
          setTimeout(
            function() {
              this.$store.state.modal = false;
            }.bind(this),
            5000
          );
        }
      } else {
        window.location.assign(
          `mailto:${this.exhibit.contact}?Subject=${this.exhibit.title}`
        );
      }
    },
    ShowProduct() {
      this.setSelectedExhibit(this.selectedExhibit);
      this.toggleShowProduct();
    }
  },
  watch: {
    selectedExhibit() {
      this.$http.get('/exhibit?uuid=' + this.selectedExhibit.uuid )
        .then(res => {
          this.exhibit = res.data;
          this.files = res.data.files['cover-image'];
          this.$http.get('/note?uuid=' + this.exhibit.note_uuid)
            .then( res => {
              this.note = res.data;
            })
            .catch( error => console.log(error));

        })
        .catch( error => console.log(error));
    }
  },
  created() {
    this.$http.get('/exhibit?uuid=' + this.selectedExhibit.uuid )
      .then(res => {
        this.exhibit = res.data;
        this.files = res.data.files['cover-image'];
        if(this.exhibit.note_uuid) {
          this.$http.get('/note?uuid=' + this.exhibit.note_uuid)
            .then( res => {
              this.note = res.data;
            })
            .catch( error => console.log(error));
        } else if( this.parentComp != 'exhibit'){
          this.$http.post('/note', {
            'exhibit_uuid': this.exhibit.uuid,
            'text': ' '
          })
            .then( res => {
              this.note = res.data;
              this.getNotes();
            })
            .catch( error => console.log(error));
        }
      })
      .catch( error => console.log(error));
  }
};
</script>

<style lang="scss" scoped>
  hr {
    background-color: rgba(255, 255, 255, 0.5);
    width: 80%;
  }

  .columns-button {
    width: 90%;
  }

  .mobile-seperator {
    display: none;
  }

  .notes-content {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 0px !important;
    min-height: 500px;
    position: relative;
  }

  ::placeholder {
    color: white;
  }

  .details-container {
    overflow-y: auto;
    scrollbar-color: $scrollbarHaefele;
    scrollbar-width: thin;
    min-height: 60vh;
  }

  .side-buttons-container {
    height: 100%;
  }

  .details {
    overflow-y: auto;
    scrollbar-color: $scrollbarHaefele;
    scrollbar-width: thin;
    min-height: 60vh;
  }

  .info {
    margin-top: 5vh;
    height: 30vh;
    margin-bottom: 10px;
  }

  .note-bottons-container {
    min-height: 5vh;
    width: 100%;
    margin-top: 10px !important;
  }

  .note-bottons-container i {
    padding-top: 5px;
  }

  .note {
    letter-spacing: 0.49px !important;
    color: #FFFFFF !important;
    font-size: 16px;
    font-weight: 400 !important;
  }

  .btn {
    background-color: white;
    font-size: 16px;
    border-radius: 0px;
    padding: 0 !important;
    text-transform: none !important;
  }

  .button-text {
    text-transform: none !important;
  }

  .note-button {
    font-size: 16px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    width: 100%;
    background-color: $secondaryColor;
  }

  .right-buttons {
    font-size: 16px;
    padding-left: 10px !important;
    padding-right: 5px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    width: 100%;
    font-weight: 400;
    letter-spacing: 0.15px;
  }

  .right-buttons:hover {
    background-color: $secondaryColor;
  }

  .image-container {
    overflow: hidden;
    text-align: center;
    display: block;
    object-fit: contain;
  }

  .brand-image {
    height: 30vh;
  }

  .note-heading {
    font-size: 28px;
    color: $secondaryColor;
    letter-spacing: 0.56px;
    text-transform: uppercase;
    font-weight: 300;
  }

  .note-details-container {
    max-height: 30vh;
    overflow-y: auto;
    scrollbar-color: $scrollbarHaefele;
    scrollbar-width: thin;
  }

  .note-details {
    font-size: 15px;
  }

  .cover-content {
    height: 58%;
  }

  .cover-content .note {
    background-color: #808080b3;
    color: white;
    font-weight: 400;
    resize: none;
    height: 70%;
    width: 100%;
    border: 0;
    border-radius: 0;
  }

  .row-no-padding {
    margin-left: 0;
    margin-right: 0;
  }

  .search-container {
    margin-left: 20px !important;
    margin-right: 40px !important;
    margin-bottom: 30px;
    border-color: rgba(255, 255, 255, 0.7);
  }

  .search-container .text-field {
    border-radius: 0%;
    background-color: rgba(0, 0, 0, 0);
    margin-bottom: 0 !important;
    color: white;
  }

  .search-icon {
    background-color: rgba(255, 255, 255, 0.7);
    font-size: 20px;
    width: 50px;
  }

  @media screen and (max-width: 760px) {
    .content{
      padding: 10px;
    }

    .close-container {
      right: 10px;
    }

    .mobile-seperator {
      display: block;
    }

    .note-details-container {
      min-height: 200px;
    }

    .note-heading {
      margin-top: 20px;
    }

    .columns-button {
      margin-top: 20px;
    }

    .brand-image {
      width: inherit;
    }

    .image-container {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    .columns-button {
      margin-left: auto;
      margin-right: auto;
    }
  }
</style>
